// import important dependencies
import 'bootstrap';

import '@fortawesome/fontawesome-free/js/all.js';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';


$('.owl-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    items: 1,
    center: true,
    autoplay: true
});


$("img").on("error", function() {
    $(this).hide();
  });